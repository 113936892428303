/* Clipart Panel Css */

.clipartpanel-search {
  margin: 0px auto 15px;
  width: calc(100% - 5px);
  position: relative;
}

.clipart-view-panel {
  height: calc(100% - 60px);
}

.shape-panel-container,
.panel-container {
  height: calc(100vh - 200px);
  overflow: auto;
  scrollbar-width: thin;
}

.panel-container > .go4160152499 {
  scrollbar-width: thin;
}

.elements-warning-message {
  padding: 15px;
  text-align: center;
  color: var(--editor-text);
}

.shape-panel-container .go4160152499:nth-of-type(2) {
  height: unset;
}

.shape-panel-container .go4160152499:nth-of-type(4) {
  overflow: unset;
}

.polotno-close-panel {
  border-radius: 12px !important;
}

.slider-icon {
  height: calc(100% - 3px) !important;
  width: 20px !important;
  background: radial-gradient(#ffffffb8, var(--polotno-tabs-bg));
  transition: 0.25s;
  cursor: pointer;
  display: flex;
  position: absolute;
  left: -1px;

  &.right-icon {
    left: unset;
    right: -1px;
  }

  svg {
    width: 24px;
    height: 24px;
    transition: 0.25s;
  }

  &:hover {
    svg {
      color: var(--editor-text);
      transform: scaleY(1.05);
    }
  }
}

.element-selected {
  display: inline-flex;
  position: relative;
  padding: 7px 14px;
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.3s;
  background: var(--linear-gradient);
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
}

.element-tag {
  display: inline-flex;
  position: relative;
  padding: 7px 14px;
  cursor: pointer;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: 0.3s;
  color: var(--editor-light-text);
  border: 1px solid var(--editor-light-border);
  transition: 0.3s;

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    border-color: var(--editor-border);
    color: var(--editor-text);
  }

  span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
  }
}

.mini-scrollbar {
  height: 100%;
  padding-bottom: 65px;
  scrollbar-width: thin;
}

.mini-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.mini-scrollbar::-webkit-scrollbar-track {
  background-color: darkgrey;
}

.mini-scrollbar::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  display: block;
  background: #9ca3af;
}
