.chat {
  font-size: calc((100vw / 1920) * 10);
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition:
    clip-path 1.5s ease-out,
    background-color 0.8s ease-in,
    width 0.8s ease-in,
    opacity 0.8s ease-in;
  background-color: white;
  overflow: hidden;
  font-weight: 300;
}

.chat > * {
  padding: 0 1.6rem;
}

.chat button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chat .caution {
  color: #656565;
  font-size: 0.65rem;
  text-align: center;
  margin: 0.5rem 16px;
  padding: 0;
}

.chat__body {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: auto;
  width: calc(100% - 16px);
  padding: 0 16px;
}

.chat__body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.chat__body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d1d5db;
}

.chat__body::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e5e7eb;
  margin: 8px;
}

.chat-button svg,
.compose-button svg {
  fill: url(#blue-purple-gradient) var(--kg-primary);
}

.chat-container.opened {
  flex-basis: 30%;
}

.chat-container {
  flex-shrink: 1;
  height: 100vh;
}

.chat-nav {
  display: flex;
  padding: 0.6rem 16px;
  height: 60px;
  box-shadow: none;
  border-bottom: 1px solid #9e9e9e52;
}

.chat-nav__group {
  display: flex;
  align-items: center;
}

.chat-nav .chat-nav__group:first-child {
  flex-grow: 1;
}

.chat-nav__group:first-child button {
  position: relative;
}

.chat-nav__group:first-child button.active::after {
  content: '';
  position: absolute;
  width: 30%;
  height: 0.2rem;
  background: linear-gradient(
    to right,
    var(--kg-secondary) 40%,
    var(--kg-primary)
  );
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.chat-nav__group button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000;
  padding: 5px;
  font-size: 1rem;
}

.chat-nav__group button:not(:last-child) {
  margin-right: 10px;
}

.chat-nav__group svg {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}

.chat-nav__group span {
  margin-right: 10px;
}

.chat-nav__group span svg {
  width: 30px;
  height: 30px;
}

.chat-nav__group .close svg {
  stroke-width: 7rem;
  stroke: #6b7280;
}

.chat-nav__group .close {
  display: inline-block;
}

.chat-nav-title {
  font-size: 16px;
  color: #374151;
  font-weight: 600;
  vertical-align: middle;
  margin-left: 8px;
}

.chat-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  right: 15px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: var(--linear-gradient);
  z-index: 10000;
  cursor: pointer;

  @media (max-width: 800px) {
    &.timeline-open {
      display: none;
    }
    bottom: 120px !important;
  }
}

.chat-widget::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  background: var(--linear-gradient);
  z-index: -1;
  border-radius: 50%;
  opacity: 0.3;
  transition: transform 0.25s ease-out;
}

.chat-widget img {
  width: 30px !important;
  height: 30px !important;
}

.chat-widget:hover::after {
  transform: scale(1.25, 1.25);
}

.chat-widget:active::after {
  transform: scale(1.1, 1.1);
  opacity: 0.5;
}

.clear-chat svg {
  fill: #fff;
  width: 1.8rem;
  height: 1.8rem;
}

.chat-single-actions {
  position: relative;
  display: inline-block;
}

.chat-single-actions:hover ul {
  display: block;
}

.chat-single-actions ul {
  display: none;
  position: absolute;
  right: 16px;
  background: #f3f4f6;
  border-radius: 4px;
  padding: 0;
  width: max-content;
  color: #374151;
  z-index: 2;
}

.chat-single-actions ul li {
  margin: 8px;
  width: calc(100% - 16px);
  border-radius: 4px;
  list-style: none;
}

.chat-single-actions ul li .btn {
  width: calc(100% - 10px);
  font-size: 12px;
}

.chat-single-actions ul li .btn:hover {
  background: white;
  color: #374151;
}

.chat-single-actions ul li .btn span {
  margin-right: 8px !important;
}

.chat-nav-right-options {
  margin-left: auto;
  display: flex;
}

footer.chat__input {
  border: 0.1rem solid #9ca3af;
  border-radius: 0.8rem;
  display: flex;
  padding: 0;
  background-color: unset;
  align-items: center;
  background-color: #fff;
  padding: 0 0.5rem;
  /* box-shadow: 0rem -0.1rem 0.4rem rgba(0, 0, 0, 0.3); */
  margin: 0 16px;
}

footer .clear-chat {
  /* margin-right: 1.4rem; */
  margin-left: 0;
}

footer .input-box {
  flex-grow: 1;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.2rem;
  align-self: stretch;
  background-color: #fff;
  /* box-shadow: 0.1rem 0.1rem 0.4rem 0.1rem rgba(0, 0, 0, 0.3); */
  border: none;
  color: #000;
}

footer .chat__input:focus {
  outline: #f00;
}

footer .input-box:focus {
  outline: none;
}

footer .send-message,
footer .clear-chat {
  /* width: 56px;
  height: 56px; */
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}

.chat footer svg {
  fill: #374151;
}

.incoming {
  background-color: #fff;
}

.incoming,
.outgoing {
  border-radius: 0.8rem;
  font-size: 16px;
  border: 1px solid #9e9e9e52;
  display: flex;
  flex-direction: column;
  max-width: 75%;
  padding: 0.8rem 1.2rem;
}

.message {
  color: #000;
  /* background-color: #fff; */
  display: flex;
  /* flex-direction: column; */
  font-size: 1.2rem;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  transition: all 5s ease-out;
  white-space: pre-line;
}

.message button {
  transition: 0.15s;
}

.message button:hover {
  transform: scale(1.05);
}

.message .options {
  margin-top: 0.6rem;
  border-radius: 0.2rem;
  align-self: flex-end;
  overflow: hidden;
  transform: scaleX(-1) rotate(180deg);
}

.message-skeleton.loading {
  height: 2rem;
  display: flex;
  /* justify-content: center; */
  flex-direction: row;
  align-items: center;
}

.message-skeleton.loading span {
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: loadingBounce;
  animation-name: loadingBounce;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: block;
  height: 0.5rem;
  width: 0.5rem;
}

.message-skeleton.loading span + span {
  margin-left: 0.25rem;
}

.message-skeleton.loading span:nth-of-type(2) {
  -webkit-animation-delay: 900ms;
  animation-delay: 900ms;
}

.message-skeleton.loading span:nth-of-type(3) {
  -webkit-animation-delay: 1000ms;
  animation-delay: 1000ms;
}

.minimize .chat {
  /* border: 2px solid #4758FB; */
  background-color: var(--kg-primary);
  clip-path: ellipse(0px 0px at 90% 90%);
  /* background-color: orangered; */
  width: 0;
  opacity: 0;
}

.opened .chat-widget {
  transform: scale(0, 0);
}

.opened .chat {
  clip-path: ellipse(100vw 100vw at 50% 50%);
  /* background-color: orange; */
}

.outgoing {
  position: relative;
  background: linear-gradient(
    90.57deg,
    rgba(var(--kg-primary-rgb), 0.05) -5.35%,
    rgba(var(--kg-secondary-rgb), 0.05) 88%
  );
  border: 0.5px solid var(--kg-primary);
  margin-left: auto;
}

.send-message:active {
  background-color: #4944e7;
}

.send-message svg {
  fill: #fff;
  width: 2rem;
  height: 2rem;
}

.suggestions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1rem;
  padding: 0 16px;
}

.suggestions > span {
  color: #6b7280;
  font-size: 14px;
  margin: 8px 0;
}

.suggestion {
  position: relative;
  color: #000;
  cursor: pointer;
  background: white;
  border: none;
  border-radius: 0.8rem;
  padding: 0.8rem 1rem;
  margin-bottom: 0.8rem;
  text-align: end;
  font-size: 16px;
}

.suggestion::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 0.9rem;
  background: var(--linear-gradient);
  z-index: -1;
}

@media (max-width: 1200px) {
  .chat-container.opened {
    position: absolute;
    width: 50vw;
    height: 60vh;
    flex-basis: 30%;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 50px);
    right: 0;
    top: 50px;
    z-index: 999;
  }

  .opened .chat {
    clip-path: ellipse(200vw 200vw at 50% 50%);
  }
}

@media (max-width: 580px) {
  .chat-widget {
    height: 40px;
    width: 40px;
    right: 15px;
  }

  .chat-widget img {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 360px) {
  .chat > * {
    padding: 0 0.4rem;
  }

  .chat-nav__group button {
    height: 1.2rem;
    width: 1.2rem;
  }

  .chat .caution {
    padding: 0 0.4rem;
    font-size: 0.5rem;
  }

  .message {
    font-size: 0.8rem;
  }

  .chat-nav {
    height: unset;
  }

  .suggestion {
    font-size: 0.8rem;
    border-width: 0.08rem;
    padding: 0.4rem 0.8rem;
  }

  .chat__input {
    margin: 0 0.4rem;
    padding: 0;
    border-width: 0.08rem;
  }

  .send-message,
  .clear-chat {
    height: 2rem;
    width: 2rem;
  }

  footer .input-box {
    font-size: 0.8rem;
  }
}
