.polotno-outerblock {
  max-width: 100vw;
  height: 100vh;
  height: 100dvh !important;
  display: flex;
  flex-direction: column;
}

.design-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100dvh;
}

.polotno-container {
  flex: 1 1 0%;
}

.polotno-outerblock > .bp5-navbar {
  z-index: 99;
  background: var(--linear-gradient);
}

.topbar-share-block {
  position: relative;
  display: inline-flex;
}

.share-dropdown {
  background: none !important;
  border-radius: 10px !important;
  .bp5-popover-content {
    background: none !important;
  }
}

.share-drop {
  background: none !important;
  border-radius: 10px;
}

.topbar-share-dropdown {
  max-width: 250px;
  background: #fff !important;
  border-radius: 8px;

  .topbar-btn {
    color: #9ca3af !important;
    width: 100%;
    border-bottom: 1px solid #9ca3af;
    text-transform: none !important;

    &:first-child {
      margin-bottom: 2px;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    span {
      width: 100%;
      text-align: left;
    }
  }
}

.polotno-editorblock {
  height: calc(100% - 50px);
  background: #e8e8e8;
}

.editorproduct-title {
  margin: 0 20px 0 0;
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  font-family: var(--kg-secondary-font);
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bp5-button.topbar-btn:hover .bp5-icon {
  color: #fff;
}

.fonts-icon path:last-child,
.backgrounds-icon path:last-child,
.resize-icon path:last-child {
  fill: #fff !important;
}

.projectpg-titleblock {
  float: right;
  margin: 0 10px;
  position: relative;
  display: flex;
  width: calc(100% - 428px);
}

.protitle-form {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  width: auto;
  max-width: 100%;
  position: relative;
}

#protitle {
  background: transparent;
  margin: 0;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  font-weight: normal;
  font-family: var(--kg-secondary-font);
  box-shadow: none !important;
  text-align: right;
  padding: 6px 10px;
  border-radius: 4px;
  border: 0.5px solid transparent;
  width: 100%;
  display: inline-block;
  margin-left: auto;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  height: 38px;
}

.protitle-text {
  font-size: 16px;
  line-height: 23px;
  padding: 6px 12px;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
  font-family: var(--kg-secondary-font);
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 38px;
  appearance: none;
  -webkit-appearance: none;
  opacity: 0;
  border: 1px solid transparent;
  pointer-events: none;
}

#protitle:hover {
  border: 0.5px solid #ffffffd7;
}

#protitle:focus {
  border: 1px solid #ffffff;
}

.protitle-btn {
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.bp5-navbar-group.bp5-align-right.projectpg-topbarrightblock {
  float: none;
  justify-content: flex-end;
}

.bp5-navbar-group > div {
  align-items: center;
}

.custom-select-dropdown {
  position: relative;
}

.custom-select {
  border: 1px solid #898989;
  width: 100%;
  background: transparent;
  border-radius: 6px;
  padding: 14px;
  color: #000;
  font-size: 15px;
  text-align: left;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.custom-select::after {
  position: absolute;
  right: 13px;
  top: 0;
  bottom: 0;
  height: 22px;
  line-height: 22px;
  content: '\f107';
  font-size: 22px;
  font-family: FontAwesome;
  margin: auto;
}

.custom-select .form-pill {
  display: inline-block;
  background: #007eb6;
  padding: 3.5px 7px;
  font-size: 9px;
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 400;
  margin-left: 10px;
  line-height: normal;
}

.dropdown-container {
  position: absolute;
  width: 100%;
  top: 0;
}

.filetypes-dropdown {
  z-index: 1000;
  list-style: none;
  width: 100%;
  position: relative;
  background: #fff;
  border: 1px solid #898989;
  border-radius: 5px;
  text-align: left;
  padding: 0;
  box-shadow: 0px 0px 5px #afafaf;
}

.filetypes-dropdown li {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  text-align: left;
  align-items: center;

  &.disabled {
    cursor: not-allowed;

    &:hover {
      background: none;
    }

    .title {
      color: #3333336c;
    }
    p {
      color: #3333336c;
    }
  }
}

.filetypes-dropdown li .download-icon-block svg {
  width: 25px;
  height: 25px;
}

.filetypes-dropdown li .download-name-block .title {
  font-weight: 500;
}

.filetypes-dropdown li .tick-circle {
  display: none;
  position: absolute;
  top: 25px;
  right: 15px;
}

.filetypes-dropdown li.active .tick-circle {
  display: block;
}

.filetypes-dropdown li.active {
  background: var(--hover-gradient);
}

.filetypes-dropdown li:hover {
  background: var(--hover-gradient);
}

.filetypes-dropdown li .title {
  font-size: 15px;
  color: #333;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.gradient-pill {
  display: inline-flex;
  position: relative;
  padding: 3px 7px;
  margin-left: 10px;
}

.gradient-pill::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 15px;
  border: 1px solid transparent;
  background-image: linear-gradient(
    to right,
    var(--kg-secondary),
    var(--kg-primary)
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.gradient-pill span {
  font-size: 9px;
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-weight: 500;
}

.filetypes-dropdown li p {
  width: 100%;
  margin: 0;
  font-size: 12px;
  text-align: left;
}

.file-type-header {
  font-size: 12px;
  color: #000;
  text-align: left;
  width: 100%;
  margin: 0 0 5px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .main .topbar-share-dropdown .bp5-button.topbar-btn {
    margin: 0;
    padding: 6px 14px !important;
  }

  .editorproduct-title {
    display: none;
  }

  .topbar-share-dropdown .bp5-button.topbar-btn .bp5-button-text {
    display: block;
  }

  .polotno-outerblock > .bp5-navbar > div {
    display: block;
  }

  .bp5-navbar-group.bp5-align-right.projectpg-topbarrightblock {
    float: right;
  }

  .projectpg-titleblock {
    display: none;
  }
}

@media (max-width: 520px) {
  .polotno-outerblock > .bp5-navbar {
    overflow: inherit;
  }
}

@media (max-width: 360px) {
  .polotno-outerblock > .bp5-navbar {
    button,
    .undo-buttons {
      button {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .editor-right-buttons {
      gap: 2px;

      button {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    .home-button-show {
      width: 22px;
      height: 22px;
    }
  }
}

@media (max-width: 320px) {
  .polotno-outerblock > .bp5-navbar {
    > div {
      display: flex !important;
      overflow-x: scroll;
    }
  }
}
